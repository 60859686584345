<script lang="ts" setup>
  import 'swiper/css';
  import 'swiper/css/pagination';
  import { Swiper, SwiperSlide } from 'swiper/vue';
  import { Pagination } from 'swiper/modules';
  import { storeToRefs } from 'pinia';

  const globalStore = useGlobalStore();
  const { globalComponentsContent } = storeToRefs(globalStore);
  const profileStore = useProfileStore();
  const { isLoggedIn, profile } = storeToRefs(profileStore);
  const dayjs = useDayjs();
  const sliderFilterTime = ref(dayjs.utc());

  const filteredSlides = computed(() => {
    return globalComponentsContent.value?.slider?.slideList?.filter(currentSlide => {
      const loggedFilter =
        (isLoggedIn.value && currentSlide.loggedHide) || (!isLoggedIn.value && currentSlide.unloggedHide);

      const isSegmentBlocked = () => {
        if (!isLoggedIn.value || !profile.value) return false;

        const showSegments = currentSlide.showSegments?.map(s => s.segmentId) || [];
        const hideSegments = currentSlide.hideSegments?.map(s => s.segmentId) || [];

        return (
          (showSegments.length && !profile.value.segmentIds.some(id => showSegments.includes(id))) ||
          (hideSegments.length && profile.value.segmentIds.some(id => hideSegments.includes(id)))
        );
      };

      const isTimeBlocked = () => {
        const FORMAT = 'DD.MM.YYYY[T]HH:mm';
        const getDayJs = (d: string) => dayjs(d, FORMAT);
        const { showFrom, showTo } = currentSlide;
        const currentTime = dayjs(sliderFilterTime.value);

        if (showFrom && showTo) return !currentTime.isBetween(getDayJs(showFrom), getDayJs(showTo), 'second');
        if (showFrom) return !currentTime.isSameOrAfter(getDayJs(showFrom), 'second');
        if (showTo) return !currentTime.isSameOrBefore(getDayJs(showTo), 'second');

        return false;
      };

      return !(loggedFilter || isSegmentBlocked() || isTimeBlocked());
    });
  });

  let sliderTimer: any;
  onMounted(() => {
    sliderTimer = setInterval(() => {
      sliderFilterTime.value = dayjs.utc();
    }, 600000);
  });

  onBeforeUnmount(() => {
    clearInterval(sliderTimer);
  });
</script>

<template>
  <Swiper class="main-banner-swiper" :slides-per-view="1" :pagination="{ clickable: true }" :modules="[Pagination]">
    <SwiperSlide v-for="(slide, index) in filteredSlides" :key="index">
      <div class="main-banner-swiper__item">
        <card-banner :slide-data="slide" />
      </div>
    </SwiperSlide>
  </Swiper>
</template>

<style src="assets/styles/components/main-banner-swiper.scss" lang="scss" scoped />
