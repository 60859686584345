<template>
  <div class="card-banner">
    <div class="card-banner__container">
      <div class="card-banner__content">
        <div class="card-banner__sub-title">
          {{ props.slideData.title }}
        </div>
        <div
          v-if="props.slideData.content"
          class="card-banner__title"
          v-html="DOMPurify.sanitize(marked.parse(props.slideData.content) as string, { FORBID_TAGS: ['style'] })"
        />
        <div v-if="showButton" class="card-banner__actions">
          <button-base class="card-banner__action-button" type="primary" size="md" @click="buttonHandleClick">
            {{ props.slideData.button?.label }}
          </button-base>
        </div>
      </div>
      
      <div class="card-banner__picture-container">
        <picture v-lazy-load  class="card-banner__picture">
          <source media="(max-width: 767px)" :data-srcset="createSrcSet(props.slideData.mobileImage)" />
          <source :data-srcset="createSrcSet(props.slideData.desktopImage)" />
          <atomic-image class="card-banner__img card-banner__img back" :src="props.slideData.desktopImage"  />
        </picture>
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
  import { marked } from 'marked';
  import type { CISliderItem } from '~/types';
  import DOMPurify from 'isomorphic-dompurify';

  const props = defineProps<{
    slideData: CISliderItem;
  }>();

  const router = useRouter();
  const { createSrcSet, localizePath } = useProjectMethods();
  const showButton = computed(() => !!props.slideData.button?.label && !!props.slideData.button?.url);

  const buttonHandleClick = (): void => {
    const url = props.slideData.button?.url;
    const targetBlankParam = props.slideData.button?.targetBlank;
    if (!url) return;

    if (url.startsWith('http')) window.open(url, targetBlankParam ? '_blank' : '_self');
    else if (targetBlankParam) window.open(localizePath(url), '_blank');
    else router.push(localizePath(url));
  };
</script>

<style src="assets/styles/components/card/banner.scss" lang="scss" scoped />
